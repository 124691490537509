<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['allergyTypes'])">
      <multi-answer-question
        title="label.allergyCauses"
        hint="label.selectAllThatApply"
        :value="allergyTypes"
        :options="$options.responseOptions"
        yes-no
        @input="onFieldChange('allergyTypes', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { ALLERGY, NONE } from '@/modules/questionnaire/api/constants';

const ALLERGIES_OPTIONS = [
  { value: ALLERGY.FOOD, text: 'allergyCause.food' },
  { value: ALLERGY.MEDICATION, text: 'allergyCause.medication' },
  { value: ALLERGY.ENVIRONMENTAL, text: 'allergyCause.environmental' },
  { value: ALLERGY.SUBSTANCE, text: 'allergyCause.substance' },
  { value: NONE, text: 'allergyCause.none', overridesAll: true, askForOtherInput: false }
];

export default {
  name: 'AllergiesTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion },
  responseOptions: ALLERGIES_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    allergyTypes: {
      type: Array,
      default: () => []
    }
  }
};
</script>
