<template>
  <h2 class="question-title u-typography-helvetica u-text u-text--s">
    <span class="question-title__text"><slot></slot></span>
    <info-tooltip v-if="hint" class="question-title__tooltip-icon" :hint="hint" />
  </h2>
</template>

<script>
import InfoTooltip from '@/modules/questionnaire/new-design-components/questions/InfoTooltip';

export default {
  name: 'QuestionTitle',
  components: { InfoTooltip },
  props: {
    hint: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.question-title {
  display: flex;

  &__text {
    flex: 1 1 auto;
    text-align: center;
  }

  &__tooltip-icon {
    align-self: flex-start;
    margin: -8px -8px 0 0;
    flex: 0 0 auto;
  }
}
</style>
