<template>
  <div
    class="q-radio"
    :class="dysfunctionClasses"
    role="radio"
    :aria-checked="checked"
    v-bind="$attrs"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    @click="onCheck"
    @focus="onCheck"
  >
    <ai-selected-tag v-if="aiSelected" class="q-radio__ai-selected-tag" />
    <div class="q-radio__content">
      <slot>{{ $t(text) }}</slot>
    </div>
    <info-tooltip v-if="hint" class="q-radio__tooltip" :hint="hint" />
  </div>
</template>

<script>
import InfoTooltip from '@/modules/questionnaire/new-design-components/questions/InfoTooltip';
import AiSelectedTag from '@/modules/questionnaire/new-design-components/inputs/AiSelectedTag';

export default {
  name: 'QRadioButton',
  components: { InfoTooltip, AiSelectedTag },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    dysfunction: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    aiSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dysfunctionClasses() {
      return {
        'q-radio--active': this.checked,
        [`q-radio--${this.dysfunction}`]: this.dysfunction
      };
    }
  },
  methods: {
    onCheck() {
      this.$emit('change', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.q-radio {
  width: 100%;
  padding: 10px 32px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--u-color-grey-200);
  background-color: transparent;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;

  --active-border-color: var(--u-color-grey-900);
  --active-background-color: var(--u-color-grey-50);

  &--active,
  &:hover,
  &[aria-checked='true'] {
    border-color: var(--active-border-color);
    background-color: var(--active-background-color);
  }

  &--oxidative-stress {
    --active-border-color: var(--u-color-oxidative-stress-500);
    --active-background-color: var(--u-color-oxidative-stress-50);
  }

  &--skin-appearance {
    --active-border-color: var(--u-color-skin-appearance-500);
    --active-background-color: var(--u-color-skin-appearance-50);
  }

  &--skin-redness {
    --active-border-color: var(--u-color-skin-redness-500);
    --active-background-color: var(--u-color-skin-redness-50);
  }

  &--skin-dryness {
    --active-border-color: var(--u-color-skin-dryness-500);
    --active-background-color: var(--u-color-skin-dryness-50);
  }

  &--skin-oiliness {
    --active-border-color: var(--u-color-skin-oiliness-500);
    --active-background-color: var(--u-color-skin-oiliness-50);
  }

  &--skin-texture {
    --active-border-color: var(--u-color-skin-texture-500);
    --active-background-color: var(--u-color-skin-texture-50);
  }

  &--pimples {
    --active-border-color: var(--u-color-pimples-500);
    --active-background-color: var(--u-color-pimples-50);
  }

  &--skin-pigmentation {
    --active-border-color: var(--u-color-skin-pigmentation-500);
    --active-background-color: var(--u-color-skin-pigmentation-50);
  }

  &__ai-selected-tag {
    position: absolute;
    top: 0;
    left: 23px;
    transform: translateY(-50%);
  }

  &__tooltip {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  &__content {
    font-family: var(--u-font-family-Gilroy);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 8px;
  }
}
</style>
